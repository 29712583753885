<template>
  <b-table
    class="mt-1"
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
  >
    <template #cell(company)="data">
      <b-link
        class="text-body"
        :to="'/app/proforma-invoices/view/' + data.item.id"
      >
        <div class="font-small-2 text-primary">
          {{ data.item.pno }}
        </div>
        {{ data.item.company }}
      </b-link>
    </template>
    <template #cell(pdate)="data">
      {{ moment(data.item.pdate).format('DD.MM.YYYY') }}
      <div class="font-small-2 text-primary">
        {{ data.item.username }}
      </div>
    </template>
    <template #cell(control)="data">
      <list-buttons
        primary-text="Görüntüle"
        :primary-action="'/app/proforma-invoices/view/' + data.item.id"
        edit-text="Güncelle"
        :edit-action="'/app/proforma-invoices/edit/' + data.item.id"
        :delete-action="removeData"
        :data-id="data.item.id"
      />
    </template>
  </b-table>
</template>
<script>
import { BTable, BLink } from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'ProformaList',
  components: {
    BTable,
    BLink,
    ListButtons,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    removeData: {
      type: Function,
      required: true,
    },
  },
}
</script>
